<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content
          class="mobile indexLayoutContent"
          style="padding: 0px"
        >
          <div
            class="page mindex payed flex-col justify-start"
            style="height: auto"
          >
            <div class="mswiper windowheight">
              <top-p :lag="lag"></top-p>
              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div :class="`swiper-slide ssm0`">
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="group_0_0 flex-col mao00">
                            <div class="g00 flex-col" :style="``">
                              <div class="g00in flex-col">
                                <div class="gbar flex-col justify-between">
                                  <div class="gdes flex-col">
                                    <div style="color: #fff; font-size:52px; font-weight:600;">
                                      {{ message }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { jsPay, jsSign, fetchSign } from "../api/index";
import TopP from "../components/TopP.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    TopP,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      pageHeight: 0,

      no: "",

      ISwx: 0,

      prepay_id: "",

      sign: {},

      message: "支付中",
    };
  },

  mounted() {
    // if (
    //   typeof WeixinJSBridge === "object" &&
    //   typeof WeixinJSBridge.invoke === "function"
    // ) {
    //   this.ISwx = 1;
    //   console.log("在微信");
    // } else {
    //   this.ISwx = 0;
    //   console.log("不在微信");
    // }

    var userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.indexOf("micromessenger") !== -1) {
      this.ISwx = 1;
      // alert("当前页面在微信中打开");
    } else {
      this.ISwx = 0;
      // alert("当前页面不在微信中打开");
    }

    let that = this;

    let no = this.$route.params.no;

    let lag = this.$route.params.lag;

    if (no) {
      this.no = no;
    }

    if (lag) {
      this.lag = lag;
    }

    that.handleScroll;
    that.resizepage();
    this.$el.addEventListener("scroll", that.handleScroll, true);
    window.addEventListener("resize", that.handleResize, true);

    setTimeout(() => {
      $("meta[name=referrer]").attr("content", "unsafe-url");
      setTimeout(() => {
        that.jspay();
      }, 500);
    }, 500);

    that.myswiper();
  },
  beforeCreate() {},
  methods: {
    onBridgeReady(data) {
      const h = this.$createElement;
      const that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号ID，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式：
          paySign: data.paySign, //微信签名
        },
        function (res) {
          // that.message = res;

          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.message = "支付成功";
            that.$info({
              title: "支付结果",
              class: "confirmDiv",
              content: h("div", {}, [h("p", "支付成功")]),
              onOk() {
                let params = { no: that.no };
                fetchSign(params).then((res) => {
                  console.log(res);
                  that.data = res.results;

                  that.$router.push(
                    `/mdetallmf/${that.data.kind}/${that.data.mid}/`
                  );
                });
              },
            });
          }else{
            that.message = "支付发生错误";
            that.$info({
            title: "支付结果",
            class: "confirmDiv",
            content: h("div", {}, [h("p", "支付发生错误")]),
            onOk() {
              let params = { no: that.no };
              fetchSign(params).then((res) => {
                console.log(res);
                that.data = res.results;

                that.$router.push(`/mdetallmf/${that.data.kind}/${that.data.mid}/`);      
      
              });
            },
          });
          }
        }
      );
    },
    jspay() {
      const that = this;
      if (this.ISwx == 0) {
        alert("请在微信打开！");
      } else {
        console.log("在微信");

        let params = { out_trade_no: this.no };

        console.log("params:", params);

        jsPay(params).then((res) => {
          console.log("res:", res);
          // alert(res);
          this.prepay_id = res.prepay_id;

          params = { prepay_id: this.prepay_id };
          jsSign(params).then((res) => {
            console.log("res:", res);
            that.sign = res;

            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
              }
            } else {
              that.onBridgeReady(that.sign);
            }
          });
        });
      }
    },
    myswiper0() {
      let that = this;

      setTimeout(() => {
        this.lswiper0 = new Swiper(".lsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,
        });
      }, 10);
    },

    myswiper1() {
      let that = this;

      setTimeout(() => {
        this.lswiper0 = new Swiper(".lsc1", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,
        });
      }, 10);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          duration: 1000,
          direction: "vertical",
          resistanceRatio: 0,
          on: {
            transitionEnd: function () {
              that.mselectIndex = this.activeIndex;
              if (that.mselectIndex > 0) {
                that.tb = false;
              } else {
                that.tb = true;
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
            },
          },
        });
      }, 10);
    },

    showdetall(kind, id) {
      this.$router.push(`/mdetallm/${kind}/${id}/${this.lag}`);
    },
    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        this.winHeight = winHeight;
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      console.log("resizeViewport m");
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/payed/${this.id}/${this.lag}`;
        // window.location.reload();
      }
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
        that.myswiper2();
      } else {
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    goIndex() {
      this.$router.push(`/indexl`);
    },

    goSelect(index) {
      this.$router.push(`/sindex/${index}/${this.lag}`);
    },

    goMindex(id2) {
      let url = "";
      let id = this.id;
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }
    },

    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexlm.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
